const flexCenterXY = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const fillEntireContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const helpers = {
  flexCenterXY,
  fillEntireContainer,
};

export default helpers;
